import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Container,
  Navbar
} from 'react-bootstrap/';
import './App.css';
import Root from './routes/root'

import GenderChart from './GenderChart';
import ErrorPage from './routes/error-page';

import VersionFooter from './VersionFooter'

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root/>,
    errorElement: <ErrorPage/>,
  },
  {
    path: "/gender",
    element: <GenderChart/>,
  }
]);

function App() {
  return (
    <div className="App">
      <Navbar bg="light">
        <Container
          style={{width: "auto"}}
        >
          <Navbar.Brand href="/"
            style={
              {
                fontFamily: "'Climate Crisis', cursive",
                color: "rgb(75, 104, 143)",
              }}
          >genderify.me</Navbar.Brand>
        </Container>
      </Navbar>
      <header className="App-header">
      <RouterProvider router={router} />
      </header>
      <VersionFooter></VersionFooter>
    </div>
  );
}

export default App;
