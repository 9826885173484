import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';


import './GenderChart.css'
import { useGenderData } from './hooks';

ChartJS.register(ArcElement, Tooltip, Legend);

// export const data = {
//     labels: ['Male', 'Female', 'Unknown'],
//     datasets: [
//         {
//             label: '# artists',
//             data: [
//                 Math.floor(Math.random() * 1000),
//                 Math.floor(Math.random() * 1000),
//                 Math.floor(Math.random() * 100),
//             ],
//             backgroundColor: [
//                 'rgba(255, 99, 132, 0.2)',
//                 'rgba(54, 162, 235, 0.2)',
//                 'rgba(22, 22, 22, 0.2)',
//             ],
//             borderColor: [
//                 'rgba(255, 99, 132, 1)',
//                 'rgba(54, 162, 235, 1)',
//                 'rgba(22, 22, 22, 1)',
//             ],
//             borderWidth: 2,
//         },
//     ],
// };

function GenderChart() {
    let [l, d] = useGenderData()
    return l? <div>Cargando</div> : <Doughnut
        className='gender-chart'
        data={d}
        updateMode="resize"
    />

}

export default GenderChart;