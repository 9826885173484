import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'

import { BsFillInfoCircleFill } from 'react-icons/bs';

function Login() {
    return  <div>
    <div id="login">
        <Button 
            style={{backgroundColor: "rgb(34, 197, 94)", margin: "15px", padding: "3%", fontWeight: "bold"}}
            href="/auth"
            >
                Spotify <br/>Login
        </Button>
        <Alert variant="info">
            <BsFillInfoCircleFill
            style={{marginRight: "20px"}}
            size={"2em"}/>
          Analyze your Spotify library to know how your music taste is spread per gender
        </Alert>
     
    </div>
    <div id="loggedin">
    </div>
 </div>
}

export default Login;
