import { useEffect, useState } from "react";

export const defaultData = {
    labels: ['Male', 'Female', 'Unknown'],
    datasets: [
        {
            label: '# artists',
            data: [
                Math.floor(Math.random() * 1000),
                Math.floor(Math.random() * 1000),
                Math.floor(Math.random() * 100),
            ],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(22, 22, 22, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(22, 22, 22, 1)',
            ],
            borderWidth: 2,
        },
    ],
};

export function useGenderData() {

    const [loading, setloading] = useState(true)
    const [data, setdata] = useState(defaultData)

    useEffect(() => {
        let f = async function(){
            let res = await fetch("/api/gender")

            if (res.status === 401) {
                window.location.href="/"
            }

            let j = await res.json()

            console.log("json", j)

            let results = new Map()
            j.reduce((acc, current) => {
                console.log(current)
                if (current.Gender === "") {
                    current.Gender = "Unknown"
                }

                if (!acc.has(current.Gender)) {
                    console.log(current.Gender, results.get(current.Gender))
                    acc.set(current.Gender, 1)
                } else {
                    
                    console.log(current.Gender, results.get(current.Gender))
                    acc.set(current.Gender, results.get(current.Gender)+1)
                    console.log(current.Gender, results.get(current.Gender))
                }
                return acc
            }, results)


            let data = {
                labels: Array.from(results.keys()),
                datasets: [
                    {
                        label: '# artists',
                        data: Array.from(results.values()),
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(22, 22, 22, 0.2)',
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(22, 22, 22, 1)',
                        ],
                        borderWidth: 2,
                    },
                ],
            };
            console.log("New data", data)
            setdata(data)
            setloading(false)
        }

        f()

    
    }, [])
    

    return [loading, data]
    
}
